import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../Layout/MainLayout";
import { Typography, Skeleton, UploadFile, Button, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import axios from "axios";
import RefreshAccessToken from "../Authentication/RefreshToken";
import { useTranslation } from "react-i18next";
import MobileDisplay from "../ArticleDisplayPageComponents/MobileDisplay";
import DesktopDisplay from "../ArticleDisplayPageComponents/DesktopDisplay";
import { InboxOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const { Title, Paragraph } = Typography;

const ArticleDisplayForPublished: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default ArticleDisplayForPublished;

type ArticleProps = {
  id: number;
  title: string;
  paragraph: string;
  author: {
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    id: number;
  };
  embedded_link: string | null;
  article_picture_1: string | null;
  article_picture_2: string | null;
  article_picture_3: string | null;
  article_picture_4: string | null;
  article_picture_5: string | null;
  article_picture_6: string | null;
  article_banner: string | null;
  post_date_time: string;
  status: string;
};

const PageDisplay: React.FC = () => {
  const textContainerRef = useRef<HTMLDivElement>(null);
  const [visibleText, setVisibleText] = useState<string>("");
  const [refreshToken, setRefreshtoken] = useState<string | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [remainingText, setRemainingText] = useState<string>("");
  const [article, setArticle] = useState<ArticleProps | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [articleTitle, setArticleTitle] = useState<string | null>(null);
  const [articleParagraph, setArticleParagraph] = useState<string | null>(null);
  const [loadingArchieve, setLoadingArchieve] = useState(false);
  const [articleDateAndTime, setArticledateAndTime] = useState<string | null>(
    null
  );
  const [embeddedLink, setEmbeddedLink] = useState<string | null>(null);
  const [bannerPicture, setBannerPicture] = useState<UploadFile[]>([]);
  const [articlePicture, setArticlePicture] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // Add a loading state
  const [isMobile, setIsMobile] = useState(false);
  const envUrl = process.env.REACT_APP_API_URL;
  const getArticleUrl = envUrl + "articles/";
  const navigate = useNavigate();

  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const splitText = () => {
      if (textContainerRef.current) {
        const maxHeight = 300;
        const container = textContainerRef.current;

        if (typeof articleParagraph === "string") {
          // First, check if the entire text fits within maxHeight
          container.innerText = articleParagraph;
          if (container.scrollHeight <= maxHeight) {
            // If entire text fits, put it all in visibleText
            setVisibleText(articleParagraph.trim());
            setRemainingText("");
            return;
          }

          let currentText = articleParagraph;
          let visible = "";
          let remaining = currentText;

          // Regex to match the end of a sentence
          const sentenceEndRegex = /[.!?]\s+/g;

          let match;
          let lastMatchIndex = 0;

          // Loop through sentence endings
          while ((match = sentenceEndRegex.exec(currentText)) !== null) {
            const nextVisible = currentText.slice(
              0,
              match.index + match[0].length
            );

            container.innerText = nextVisible;

            if (container.scrollHeight > maxHeight) {
              break;
            }

            visible = nextVisible;
            lastMatchIndex = match.index + match[0].length;
          }

          // If no sentence breaks were found or visible is still empty
          if (!visible && currentText.length > 0) {
            for (let i = 0; i < currentText.length; i++) {
              container.innerText = currentText.slice(0, i + 1);
              if (container.scrollHeight > maxHeight) {
                visible = currentText.slice(0, i);
                remaining = currentText.slice(i);
                break;
              }
            }
          } else {
            remaining = currentText.substring(lastMatchIndex);
          }

          setVisibleText(visible.trim());
          setRemainingText(remaining.trim());
        }
      }
    };

    splitText();
    window.addEventListener("resize", splitText);

    return () => window.removeEventListener("resize", splitText);
  }, [article]);

  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const isLoggedInRedux: boolean = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );

  useEffect(() => {
    setIsLoggedIn(isLoggedInRedux);

    const checkLoginAndFetchArticles = async () => {
      if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
        const refreshTokens = isLoggedInRedux2.user.refreshToken;
        setRefreshtoken(refreshTokens);
        const refreshedAccessToken = await RefreshAccessToken(refreshTokens);
        setAccessToken(refreshedAccessToken);
        if (refreshedAccessToken) {
          await GetArticleData(refreshedAccessToken);
        }
      } else {
        console.log("User is not logged in or access token is missing.");
      }
    };
    checkLoginAndFetchArticles();
  }, [isLoggedInRedux, isLoggedInRedux2]);

  const GetArticleData = async (accessToken: string) => {
    try {
      const response = await axios.get(`${getArticleUrl}${id}/`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      const articleData = response.data.data;
      setArticle(articleData); // Store the article data in state
      setArticleTitle(articleData?.title);
      setArticleParagraph(articleData?.paragraph);
      setArticledateAndTime(articleData?.post_date_time);
      setEmbeddedLink(articleData?.embedded_link);
      if (articleData.article_picture) {
        setArticlePicture([
          {
            uid: "-1",
            name: "article_picture.png",
            status: "done",
            url: articleData.article_picture,
          },
        ]);
      }
      if (articleData.article_banner) {
        setBannerPicture([
          {
            uid: "-1",
            name: "article_banner.png",
            status: "done",
            url: articleData.article_banner,
          },
        ]);
      }
      setLoading(false); // Data has been loaded
    } catch (error) {
      console.log("Unexpected Error During Fetching Articles:", error);
      setLoading(false); // Data loading has failed
    }
  };
  const convertUrlToFile = async (url: string, index: number) => {
    try {
      const response = await fetch(url);
      console.log("response");
      console.log(url);
      const blob = await response.blob();
      const filename = `article_picture_${index + 1}.jpg`;
      console.log(filename);
      return new File([blob], filename, { type: blob.type });
    } catch (error) {
      console.error("Error converting URL to file:", error);
      return null;
    }
  };
  const prepareUpdateObject = async (data: any, status: string) => {
    const updateObject: Record<string, any> = {};
    if (data.status) updateObject.status = status;
    if (data.title) updateObject.title = data.title;
    if (data.paragraph) updateObject.paragraph = data.paragraph;
    if (data.embedded_link) updateObject.embedded_link = data.embedded_link;
    if (data.article_banner) {
      updateObject.article_banner = await convertUrlToFile(
        data.article_banner,
        0
      );
    }
    // Dynamically include only non-null article pictures
    for (let i = 1; i <= 6; i++) {
      const key = `article_picture_${i}`;
      if (data[key]) {
        updateObject[key] = await convertUrlToFile(data[key], i);
      }
    }
    data.published_date = data.published_date = dayjs().format("YYYY-MM-DD");
    return updateObject;
  };

  const archiveArticle = async () => {
    setLoadingArchieve(true);
    const status = "ARCHIVED";
    const updateData = await prepareUpdateObject(article, status);
    const publishedUrl = envUrl + `articles/${id}/`;
    let accessToken;
    if (refreshToken !== null) {
      accessToken = await RefreshAccessToken(refreshToken);
    }
    console.log(updateData);
    try {
      const response = await axios.patch(publishedUrl, updateData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      message.success("Article Archieved");
      setLoadingArchieve(false);
      setTimeout(() => {
        navigate("/archived");
      }, 1000);
    } catch (error) {
      setLoadingArchieve(false);
      message.error("Something went wrong! Please try again");
    }
  };

  const imageUrls = [
    article?.article_picture_1,
    article?.article_picture_2,
    article?.article_picture_3,
    article?.article_picture_4,
    article?.article_picture_5,
    article?.article_picture_6,
  ].filter((url): url is string => url !== null); // Ensuring only valid strings

  return (
    <div>
      {loading ? (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <>
          {isMobile ? (
            <>
              {" "}
              <MobileDisplay
                article={article}
                bannerPicture={bannerPicture}
                imageUrls={imageUrls}
              />
              <Button
                block
                type="primary"
                onClick={archiveArticle}
                style={{ marginTop: "4%" }}
                loading={loadingArchieve}
                icon={<InboxOutlined />}
              >
                {t("archive")}
              </Button>
            </>
          ) : (
            <>
              <DesktopDisplay
                article={article}
                bannerPicture={bannerPicture}
                imageUrls={imageUrls}
                visibleText={visibleText}
                remainingText={remainingText}
                textContainerRef={textContainerRef}
              />
              <Button
                block
                type="primary"
                onClick={archiveArticle}
                style={{ marginTop: "4%" }}
                loading={loadingArchieve}
                icon={<InboxOutlined />}
              >
                {t("archive")}
              </Button>
            </>
          )}
        </>
      )}
    </div>
  );
};
