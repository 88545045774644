import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Pagination,
  Spin,
  DatePicker,
  DatePickerProps,
  Flex,
} from "antd";
import MainLayout from "../Layout/MainLayout";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import axios from "axios";
import RefreshAccessToken from "../Authentication/RefreshToken";
import type { Dayjs } from "dayjs";
import { selectUser } from "../../state/selector/selectors";
import ArticleCardAuthor from "./ArticleCardAuthor";
import { useTranslation } from "react-i18next";
const { Title } = Typography;

interface Article {
  id: number;
  title: string;
  paragraph: string;
  author: {
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    id: number;
  };
  embedded_link: string | null;
  article_picture: string;
  article_banner: string;
  post_date_time: string;
  status: string;
}

const AllArticlesByAuthorPublished: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default AllArticlesByAuthorPublished;

const PageDisplay: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [articles, setArticles] = useState<Article[] | null>([]);
  const [isLoading, setIsLoading] = useState(true);
  const envUrl = process.env.REACT_APP_API_URL;
  const { t } = useTranslation();
  const pageSize = 9; // 3 rows * 3 cards per row

  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const currentUser = useSelector(selectUser);

  const isLoggedInRedux: boolean = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );

  useEffect(() => {
    console.log(currentUser?.user.id);
    setArticles([]);
    const checkLoginAndFetchArticles = async () => {
      setIsLoading(true);

      if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
        const refreshToken = isLoggedInRedux2.user.refreshToken;
        const accessToken = await RefreshAccessToken(refreshToken);
        if (accessToken) {
          const articlesData = await GetAllArticles(accessToken);
          if (articlesData) {
            setArticles(articlesData);
          }
        }
      }

      setIsLoading(false);
    };

    checkLoginAndFetchArticles();
  }, []);

  const GetAllArticles = async (
    accessToken: string
  ): Promise<Article[] | null> => {
    try {
      let allArticlesUrl: string;
      if (currentUser?.user.is_superuser) {
        allArticlesUrl = envUrl + `articles?status=PUBLISHED`;
      } else {
        allArticlesUrl =
          envUrl + `articles?author=${currentUser?.user.id}&status=PUBLISHED`;
      }
      //10.5.5.5:8000/api/articles?author=1&status=PUBLISHED
      console.log(allArticlesUrl);
      const response = await axios.get(allArticlesUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // console.log();
      console.log(response.data.data.results);
      return response.data.data.results as Article[];
    } catch (error) {
      console.log("Unexpected Error During Fetching Articles:", error);
      return null;
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const currentArticles = articles
    ? articles.slice(startIndex, startIndex + pageSize)
    : [];
  const onChange: DatePickerProps<Dayjs[]>["onChange"] = (date, dateString) => {
    console.log(date, dateString);
  };

  return (
    <Spin spinning={isLoading} size="large">
      <Title
        level={2}
        style={{
          padding: "0",
          margin: "0",
          marginBottom: "1%",
          textAlign: "left",
        }}
      >
        {t("your_published_article")}
      </Title>
      <DatePicker
        onChange={onChange}
        style={{
          marginBottom: "2%",
          marginTop: "0px",
          paddingTop: "0px",
          width: "20%",
        }}
      />
      <Row gutter={[16, 16]}>
        {currentArticles.map((article) => (
          <Col xs={24} sm={12} md={8} key={article.id}>
            <ArticleCardAuthor {...article} />
          </Col>
        ))}
      </Row>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={articles.length}
        onChange={handlePageChange}
        style={{
          marginTop: "20px",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      />
    </Spin>
  );
};
