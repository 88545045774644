import React, { useState, useEffect } from "react";
import { Table, Typography, Tabs, Spin, Flex } from "antd";
import MainLayout from "../Layout/MainLayout.tsx";
import "../../styles/DynamicData.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import fetchAllMetaDetailsOdds from "../CommonApiCalls/metaDataRaceInformation.tsx";
import FetchRaceInformation from "../Mapper/raceInformationMapper.ts";
import { RootState } from "../../store.ts";
import TitleCard from "./Responsive Title/TitleCard.tsx";
import Segment from "./Responsive Title/Segment.tsx";
import TitleCardDesktop from "./Responsive Title/TitleCardDesktop.tsx";

const { Title } = Typography;
const { TabPane } = Tabs;

interface HorseData {
  horse_no: string;
  last_6_runs: string;
  colour: string;
  horse: string;
  weight: string;
  jockey: string;
  draw: string;
  trainer: string;
  rating: string;
  rating_change: string;
  horse_wt_declaration: string;
  priority: string;
  gear: string;
}

interface RaceInfo {
  id: number;
  title: string;
  time_location: string;
  match_condition: string;
  match_reward: string;
}

const UpcomingRace = () => {
  return (
    <div>
      <MainLayout Component={TableInfo} />
    </div>
  );
};
export default UpcomingRace;

const TableInfo = () => {
  const envUrl = process.env.REACT_APP_API_URL;
  const { t } = useTranslation();
  const [raceNumber, setRaceNumber] = useState<number>(1);
  const [raceData, setRaceData] = useState<any[]>([]);
  const [raceTitle, setRaceTitle] = useState<string>("");
  const [raceDate, setRaceDate] = useState<string>("");
  const [courseDetails, setCourseDetails] = useState<string>("");
  const [isMobile, setIsMobile] = useState(false);
  const [maxRaceNumber, setMaxRaceNumber] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const currentLanguage = useSelector((state: RootState) => state.language.lng);

  const fetchMetaRaceDataDetails = async () => {
    const [metaRaceData, metaRaceDataInfo] = await fetchAllMetaDetailsOdds(
      currentLanguage
    );
    return [metaRaceData, metaRaceDataInfo];
  };

  const fetchedRaceInformation = (
    raceNumber: number,
    metaRaceDataDetails: any,
    metaRaceDataDetailsInfo: any
  ) => {
    console.log(metaRaceDataDetailsInfo);
    const raceDayPlaceVenue = Object.keys(metaRaceDataDetailsInfo)
      .filter((key: any) => !isNaN(key)) // Exclude any non-numeric keys (e.g., "_persist")
      .sort((a: any, b: any) => a - b) // Sort the numeric keys in ascending order
      .map((key) => metaRaceDataDetailsInfo[key]) // Map the keys to their corresponding values
      .join(""); // Join them into a string
    console.log(raceDayPlaceVenue);

    const [raceTitle, raceDayPlace, restOfDetails] = FetchRaceInformation(
      raceNumber,
      raceDayPlaceVenue,
      metaRaceDataDetails,
      currentLanguage
    );
    setRaceTitle(raceTitle);
    setRaceDate(raceDayPlace);
    setCourseDetails(restOfDetails);
  };

  const fetchedMetaDataDetails = (
    raceNumber: number,
    metaRaceDataDetails: any
  ) => {
    if (metaRaceDataDetails[raceNumber - 1]) {
      const runners = metaRaceDataDetails[raceNumber - 1].runners;
      let horseDetails;
      if (Array.isArray(runners)) {
        horseDetails = runners
          .filter((runner) => runner.barrierDrawNumber !== "") // Filter out runners with empty barrierDrawNumber
          .map((runner, index) => ({
            horseNumber: index + 1,
            color: runner.horse.code,
            horseName:
              currentLanguage === "zh" ? runner.name_ch : runner.name_en,
            last6Run: runner.last6run,
            weight: runner.handicapWeight,
            currentWeight: runner.currentWeight,
            jockey:
              currentLanguage === "zh"
                ? runner.jockey?.name_ch || "N/A"
                : runner.jockey?.name_en || "N/A",
            draw: runner.barrierDrawNumber,
            trainer:
              currentLanguage === "zh"
                ? runner.trainer?.name_ch || "N/A"
                : runner.trainer?.name_en || "N/A",
            rating: runner.currentRating,
            gear: runner.gearInfo,
          }));

        console.log(horseDetails);
        setRaceData(horseDetails);
        return horseDetails;
      }
    }
  };

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const [metaRaceData, metaRaceDataInfo] =
          await fetchMetaRaceDataDetails();
        console.log(metaRaceData);
        fetchedMetaDataDetails(raceNumber, metaRaceData);
        const totalRaces = Object.keys(metaRaceData).length;
        setMaxRaceNumber(totalRaces);
        fetchedRaceInformation(raceNumber, metaRaceData, metaRaceDataInfo);

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 60000);
    return () => {
      clearInterval(intervalId);
    };
  }, [raceNumber, currentLanguage]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const desktopColumns = [
    {
      title: t("horse_number"),
      dataIndex: "horseNumber",
      key: "Horse number",
    },

    {
      title: t("color"),
      dataIndex: "color",
      key: "Brand number",
      render: (text: string) => (
        <img
          src={`https://racing.hkjc.com/racing/content/Images/RaceColor/${text}.gif`}
        />
      ),
    },
    {
      title: t("horse_name"),
      dataIndex: "horseName",
      key: "Horse name",
    },
    {
      title: t("draw"),
      dataIndex: "draw",
      key: "draw",
    },
    {
      title: t("last_6_runs"),
      dataIndex: "last6Run",
      key: "Last 6 runs",
    },
    {
      title: t("weight"),
      dataIndex: "weight",
      key: "Weight",
    },
    {
      title: t("current_weight"),
      dataIndex: "currentWeight",
      key: "Current Weight",
    },
    {
      title: t("jockey"),
      dataIndex: "jockey",
      key: "Jockey",
    },

    {
      title: t("trainer"),
      dataIndex: "trainer",
      key: "Trainer",
    },
    {
      title: t("rating"),
      dataIndex: "rating",
      key: "Rating",
    },
    {
      title: t("gear"),
      dataIndex: "gear",
      key: "gear",
    },
  ];

  const mobileColumns = [
    {
      title: t("colors"),
      dataIndex: "color",
      key: "Brand number",
      render: (text: string) => (
        <img
          src={`https://racing.hkjc.com/racing/content/Images/RaceColor/${text}.gif`}
        />
      ),
    },
    {
      title: t("horse_information"),
      key: "Names",
      render: (text: any, record: any) => (
        <div>
          <p style={{ fontSize: "0.7rem" }}>
            <strong>{t("no.")}:</strong> {record.horseNumber}
          </p>
          <p style={{ fontSize: "0.7rem" }}>
            <strong>{t("horse_name")}:</strong> {record.horseName}
          </p>
          <p style={{ fontSize: "0.7rem" }}>
            <strong>{t("jockey")}:</strong> {record.jockey}
          </p>
          <p style={{ fontSize: "0.7rem" }}>
            <strong>{t("trainer")}:</strong> {record.trainer}
          </p>
          {/* <p style={{ color: "white" }}>
            <strong>{""}.</strong> {""}
          </p> */}
        </div>
      ),
    },
    {
      title: t("horse_statistics"),
      dataIndex: "last6Run",
      key: "Last 6 runs",
      render: (text: any, record: any) => (
        <div>
          <p style={{ fontSize: "0.7rem" }}>
            <strong>{t("draw")}:</strong> {record.draw}
          </p>
          <p style={{ fontSize: "0.7rem" }}>
            <strong>{t("last_6_runs")}:</strong> {record.last6Run}
          </p>
          <p style={{ fontSize: "0.7rem" }}>
            <strong>{t("weight")}</strong> {record.weight}
          </p>
          <p style={{ fontSize: "0.7rem" }}>
            <strong>{t("gear")}:</strong> {record.gear}
          </p>
          {/* <p style={{ color: "white" }}>
            <strong>{""}.</strong> {""}
          </p>{" "} */}
        </div>
      ),
    },
  ];

  const onChange = (key: string) => {
    setRaceNumber(parseInt(key));
  };

  const items = Array.from({ length: maxRaceNumber }, (_, i) => ({
    key: (i + 1).toString(),
    label: `${t("race")} ${i + 1}`,
    children: "",
  }));
  const [day, date, venue] = raceDate.split(",").map((item) => item.trim());
  return (
    <div className="dynamicTable">
      {isMobile && (
        <>
          <Segment value="upcoming_race" />
        </>
      )}{" "}
      {isMobile ? (
        <TitleCard
          title={t("upcoming_race")}
          date={date}
          venue={venue}
          day={day}
          maxRaceNumber={maxRaceNumber}
          onChange={onChange}
        />
      ) : (
        <>
          <TitleCardDesktop
            title={t("upcoming_race")}
            date={date}
            venue={venue}
            day={day}
            maxRaceNumber={maxRaceNumber}
            onChange={onChange}
          />
        </>
      )}
      {loading ? (
        <Spin tip="Loading" size="large">
          <Table
            dataSource={raceData}
            tableLayout="auto"
            columns={isMobile ? mobileColumns : desktopColumns} // Use mobile or desktop columns based on screen size
            size="small"
            pagination={false}
            rowClassName={(_, index) =>
              index % 2 === 0 ? "row-even" : "row-odd"
            }
          />
        </Spin>
      ) : (
        <>
          {isMobile ? (
            <>
              <Flex gap={5} style={{ marginBottom: "12px" }}>
                {" "}
                <p
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    margin: "0px",
                    fontSize: "0.7rem",
                    paddingLeft: "4px",
                  }}
                >
                  {raceTitle}
                </p>
                <p
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    margin: "0px",
                    fontSize: "0.7rem",
                    textAlign: "right",
                    paddingRight: "4px",
                  }}
                >
                  {courseDetails}
                </p>
              </Flex>
              <div
                className="table-container"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "16px",
                }}
              >
                <Table
                  dataSource={raceData}
                  columns={isMobile ? mobileColumns : desktopColumns} // Use mobile or desktop columns based on screen size
                  tableLayout="auto"
                  size="small"
                  pagination={false}
                  style={{ width: "100%" }}
                  rowClassName={(_, index) =>
                    index % 2 === 0 ? "row-even" : "row-odd"
                  } // Alternating row classes
                />
              </div>
            </>
          ) : (
            <>
              <Flex
                justify="space-between"
                style={{
                  marginBottom: "12px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                <p
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    margin: "0px",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {raceTitle}
                </p>
                <p
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    margin: "0px",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {courseDetails}
                </p>
              </Flex>
              <div
                className="table-container"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "16px",
                }}
              >
                <Table
                  dataSource={raceData}
                  columns={isMobile ? mobileColumns : desktopColumns} // Use mobile or desktop columns based on screen size
                  tableLayout="auto"
                  size="small"
                  style={{ width: "100%" }}
                  pagination={false}
                  rowClassName={(_, index) =>
                    index % 2 === 0 ? "row-even" : "row-odd"
                  } // Alternating row classes
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
