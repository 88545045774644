import React, { useEffect, useState } from "react";
import MainLayout from "../Layout/MainLayout.tsx";
import "../../styles/Home2.css";
import PageBanner from "../../pictures/channels4_banner.jpg";
import fetchAllMetaDetailsOdds from "../CommonApiCalls/metaDataRaceInformation.tsx";
import { useSelector } from "react-redux";
import ArticleCardHome from "../Aritcle/ArticleCardHome.tsx";
import RefreshAccessToken from "../Authentication/RefreshToken.tsx";
import axios from "axios";
import Slider from "react-slick";
import UpcomingMatchesCard from "./Home_Modules/UpcomingMatchesCard.tsx";
import { RootState } from "../../store.ts";
import UpcomingMatchesCardMobile from "./Home_Modules/UpcomingMatchesCardMobile.tsx";
import { Button, Flex, Skeleton, Spin, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Title from "antd/lib/typography/Title";
import { useTranslation } from "react-i18next";

function Home2() {
  return (
    <div style={{ padding: 0, margin: 0 }}>
      <MainLayout Component={Home} />
    </div>
  );
}

export default Home2;

interface Article {
  id: number;
  title: string;
  paragraph: string;
  author: {
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    id: number;
  };
  embedded_link: string | null;
  article_picture: string;
  article_banner: string;
  post_date_time: string;
  status: string;
}

interface ParamsProp {
  published_date?: string | string[];
}
interface raceDetails {
  raceNumber: number;
  raceTitle: string;
  raceTrack: string;
  raceCourse: string;
  raceDistance: string | number; // Depending on the data type of distance
  raceStartTime: string; // Assuming this is a formatted time string
  raceDayPlace: string;
  totalHorses: number;
  venueCode: string;
}

const Home = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [publishedDate, setPublishedDate] = useState<string[] | string>([]);
  const [articles, setArticles] = useState<Article[]>([]);
  const [cardRaces, setCardRaces] = useState<raceDetails[]>([]);
  const [isLoadingRaceInfo, setIsLoadingRaceInfo] = useState<boolean>(true);
  const [isLoadingArticles, setIsLoadingArticles] = useState<boolean>(true);
  const [videoId, setVideoId] = useState<any>("");
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const envUrl = process.env.REACT_APP_API_URL;
  const allArticlesUrl: string = envUrl + "articles/";
  const currentLanguage: string = useSelector(
    (state: RootState) => state.language.lng
  );
  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const isLoggedInRedux: boolean = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const navigate = useNavigate();
  const { Text } = Typography;
  const API_KEY = "AIzaSyCsa9yjYo9gYoUGN5cx_ZlY4C_6srspwR4";
  const CHANNEL_ID = "UCQUDZ0_EZGAlEaC7wXt-yoA";
  const BASE_URL = "https://www.googleapis.com/youtube/v3";

  const fetchMetaRaceDataDetails = async () => {
    const [metaRaceData, metaRaceDataInfo, venueCode] =
      await fetchAllMetaDetailsOdds(currentLanguage);
    return [metaRaceData, metaRaceDataInfo, venueCode];
  };

  const hongkongTimeConverter = (postTime: string) => {
    const dateObj = new Date(postTime);
    const timeStr = dateObj.toLocaleTimeString("en-GB", {
      hour12: false,
      timeZone: "Asia/Hong_Kong",
    });
    return timeStr;
  };

  const fetchDataCard = (
    metaRaceDataDetails: any,
    metaDataDetailsInfo: any,
    venueCode: string
  ) => {
    setIsLoadingRaceInfo(true);
    let horseDetails: raceDetails[];
    try {
      horseDetails = metaRaceDataDetails.map((race: any, index: number) => ({
        raceNumber: index + 1,
        raceTitle:
          currentLanguage === "zh" ? race.raceName_ch : race.raceName_en,
        raceTrack:
          currentLanguage === "zh"
            ? race.raceTrack.description_ch
            : race.raceTrack.description_en,
        raceCourse:
          currentLanguage === "zh"
            ? race.raceCourse.description_ch
            : race.raceCourse.description_en,
        raceDistance: currentLanguage === "zh" ? race.distance : race.distance,
        raceStartTime: hongkongTimeConverter(race.postTime),
        raceDayPlace: metaDataDetailsInfo,
        totalHorses: race.runners.length,
        venueCode: venueCode,
      }));

      console.log(horseDetails);
      setIsLoadingRaceInfo(false);
      setCardRaces(horseDetails);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchLatestVideo = async () => {
    try {
      // Fetch the uploads playlist ID
      const playlistResponse = await axios.get(
        `https://www.googleapis.com/youtube/v3/channels?part=contentDetails&id=${CHANNEL_ID}&key=${API_KEY}`
      );

      const uploadsPlaylistId =
        playlistResponse.data.items[0].contentDetails.relatedPlaylists.uploads;

      // Fetch the latest video from the uploads playlist
      const videosResponse = await axios.get(
        `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${uploadsPlaylistId}&maxResults=1&key=${API_KEY}`
      );

      const latestVideo = videosResponse.data.items[0];
      setVideoId(latestVideo.snippet.resourceId.videoId);
    } catch (error) {
      console.error("Error fetching the latest video:", error);
    }
  };

  useEffect(() => {
    // Function to fetch data
    setLoading(true);
    const fetchData = async () => {
      const [metaRaceData, metaRaceDataInfo, venueCode] =
        await fetchMetaRaceDataDetails();
      fetchDataCard(metaRaceData, metaRaceDataInfo, venueCode);
      await fetchLatestVideo();
      setLoading(false);
    };
    const checkLoginAndFetchArticles = async () => {
      setIsLoadingArticles(true);
      if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
        const refreshToken = isLoggedInRedux2.user.refreshToken;
        const accessToken = await RefreshAccessToken(refreshToken);
        if (accessToken) {
          const articlesData = await GetAllArticles(accessToken);
          if (articlesData) {
            console.log(articlesData);
            setIsLoadingArticles(false);
            setArticles(articlesData);
          }
        }
      }
    };

    fetchData();
    checkLoginAndFetchArticles();
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, [currentLanguage, isLoggedInRedux, isLoggedInRedux2]);

  const GetAllArticles = async (
    accessToken: string
  ): Promise<Article[] | null> => {
    const params: ParamsProp = {};
    if (publishedDate) {
      params.published_date = publishedDate;
    }
    try {
      const response = await axios.get(allArticlesUrl, {
        params,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // console.log();
      return response.data.data.results as Article[];
    } catch (error) {
      console.log("Unexpected Error During Fetching Articles:", error);
      return null;
    }
  };

  const settings = {
    dots: true,
    infinite: isMobile,
    speed: 500,
    slidesToShow: 4, // Adjust based on how many you want to show
    slidesToScroll: 1,
    centerMode: false, // Enable partial article viewing
    responsive: [
      {
        breakpoint: 1730,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const containerStyle: React.CSSProperties = {
    marginTop: "30px",
    height: "200px",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    backgroundColor: "rgba(200, 200, 200, 0.5)", // Transparent background
  };

  const buttonStyle: React.CSSProperties = {
    borderColor: "#1890ff",
    color: "#1890ff",
    background: "transparent", // Transparent background
    fontSize: "16px", // Adjust font size as needed
    marginTop: "80px",
  };

  const upcomingRaceSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Adjust based on how many you want to show
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1730,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div
      className="mainContainer"
      style={{ paddingTop: "0px", display: "block", width: "100%" }}
    >
      <div
        className="bodyContainer"
        style={{ paddingTop: "0px", display: "block", width: "100%" }}
      >
        <div
          className="bannerArea"
          style={{ paddingTop: "0px", display: "block", width: "100%" }}
        >
          {isMobile ? (
            <>
              <img
                src={PageBanner}
                className="channelBanner"
                alt="YouTube Channel Banner"
                style={{
                  width: "100%",
                  objectFit: "cover",
                  marginBottom: "30px",
                }}
              />
            </>
          ) : (
            <>
              <img
                src={PageBanner}
                className="channelBanner"
                alt="YouTube Channel Banner"
                style={{
                  width: "100%",
                  maxHeight: "200px",
                  objectFit: "cover",
                  marginBottom: "30px",
                }}
              />
            </>
          )}
        </div>
        <div className="bodyArea">
          <div className="youtubeHighlighted">
            {isMobile ? (
              <>
                {" "}
                <iframe
                  width="100%"
                  height="200"
                  src={`https://www.youtube.com/embed/${videoId}`}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </>
            ) : (
              <>
                {" "}
                <iframe
                  width="100%"
                  height="350"
                  src={`https://www.youtube.com/embed/${videoId}`}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </>
            )}
          </div>{" "}
          {isMobile ? (
            <>
              <div className="upcomingMatches" style={{ marginBottom: "40px" }}>
                <Slider {...upcomingRaceSettings}>
                  {cardRaces.map((cardRace: raceDetails) => (
                    <div>
                      <UpcomingMatchesCardMobile {...cardRace} />
                    </div>
                  ))}
                </Slider>
              </div>
            </>
          ) : (
            <>
              <div className="upcomingMatches" style={{ maxWidth: "50%" }}>
                {isLoadingRaceInfo ? (
                  <>
                    {" "}
                    <Flex vertical>
                      <Skeleton.Image
                        active
                        style={{ width: "100%", height: "200px" }}
                      />
                      <Flex>
                        <Skeleton active paragraph={{ rows: 2 }} />
                        <Skeleton.Node
                          active
                          style={{ width: 160, margin: "10px" }}
                        />
                      </Flex>
                    </Flex>
                  </>
                ) : (
                  <>
                    {" "}
                    <Slider {...upcomingRaceSettings}>
                      {cardRaces.map((cardRace: raceDetails) => (
                        <div>
                          <UpcomingMatchesCard {...cardRace} />
                        </div>
                      ))}
                    </Slider>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        {isLoggedInRedux ? (
          <>
            {" "}
            {isLoadingArticles ? (
              <div style={{ marginTop: "100px" }}>
                <Flex justify="center" align="center">
                  <Spin></Spin>
                </Flex>
              </div>
            ) : (
              <>
                <Flex justify="space-between">
                  <Title level={3}>{t("articles")}</Title>
                  <p style={{ cursor: "pointer", marginTop: "30px" }}>
                    <Link to="/articles" style={{ color: "#1890ff" }}>
                      {t("show_all")}
                    </Link>
                  </p>
                </Flex>
                <div className="articles">
                  <Slider {...settings}>
                    {articles.map((article: Article) => (
                      <div key={article.id}>
                        <ArticleCardHome {...article} />
                      </div>
                    ))}
                  </Slider>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {" "}
            <div style={containerStyle}>
              <Button
                type="primary"
                shape="round"
                style={buttonStyle}
                onClick={() => navigate("/login")}
              >
                <Text style={{ color: "#1890ff" }}>
                  {t("login_to_see_article")}
                </Text>
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
