import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../Layout/MainLayout";
import { Typography, Skeleton, UploadFile, Flex, Carousel } from "antd";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import axios from "axios";
import RefreshAccessToken from "../Authentication/RefreshToken";
import MobileDisplay from "../ArticleDisplayPageComponents/MobileDisplay";
import DesktopDisplay from "../ArticleDisplayPageComponents/DesktopDisplay";
const { Title, Paragraph } = Typography;

const ArticleDisplay: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default ArticleDisplay;

type ArticleProps = {
  id: number;
  title: string;
  paragraph: string;
  author: {
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    id: number;
  };
  embedded_link: string | null;
  article_picture_1: string | null;
  article_picture_2: string | null;
  article_picture_3: string | null;
  article_picture_4: string | null;
  article_picture_5: string | null;
  article_picture_6: string | null;
  article_banner: string | null;
  post_date_time: string;
  status: string;
};

const PageDisplay: React.FC = () => {
  const textContainerRef = useRef<HTMLDivElement>(null);
  const [visibleText, setVisibleText] = useState<string>("");
  const [refreshToken, setRefreshtoken] = useState<string | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [remainingText, setRemainingText] = useState<string>("");
  const [article, setArticle] = useState<ArticleProps | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [articleTitle, setArticleTitle] = useState<string | null>(null);
  const [articleParagraph, setArticleParagraph] = useState<string | null>(null);
  const [articleDateAndTime, setArticledateAndTime] = useState<string | null>(
    null
  );
  const [embeddedLink, setEmbeddedLink] = useState<string | null>(null);
  const [isMobile, setIsMobile] = useState(false);
  const [bannerPicture, setBannerPicture] = useState<UploadFile[]>([]);
  const [articlePicture, setArticlePicture] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // Add a loading state

  const envUrl = process.env.REACT_APP_API_URL;
  const refreshAccessUrl: string = envUrl + "token/refresh/";
  const getArticleUrl = envUrl + "articles/";
  const { id } = useParams();

  useEffect(() => {
    const splitText = () => {
      if (textContainerRef.current) {
        const maxHeight = 250;
        const container = textContainerRef.current;

        if (typeof articleParagraph === "string") {
          // First, check if the entire text fits within maxHeight
          container.innerText = articleParagraph;
          if (container.scrollHeight < maxHeight) {
            // If entire text fits, put it all in visibleText
            setVisibleText(articleParagraph.trim());
            setRemainingText("");
            return;
          }

          let currentText = articleParagraph;
          let visible = "";
          let remaining = currentText;

          // Regex to match the end of a sentence
          const sentenceEndRegex = /[.!?]\s+/g;

          let match;
          let lastMatchIndex = 0;

          // Loop through sentence endings
          while ((match = sentenceEndRegex.exec(currentText)) !== null) {
            const nextVisible = currentText.slice(
              0,
              match.index + match[0].length
            );

            container.innerText = nextVisible;

            if (container.scrollHeight > maxHeight) {
              break;
            }

            visible = nextVisible;
            lastMatchIndex = match.index + match[0].length;
          }

          // If no sentence breaks were found or visible is still empty
          if (!visible && currentText.length > 0) {
            for (let i = 0; i < currentText.length; i++) {
              container.innerText = currentText.slice(0, i + 1);
              if (container.scrollHeight > maxHeight) {
                visible = currentText.slice(0, i);
                remaining = currentText.slice(i);
                break;
              }
            }
          } else {
            remaining = currentText.substring(lastMatchIndex);
          }

          setVisibleText(visible.trim());
          setRemainingText(remaining.trim());
        }
      }
    };

    splitText();

    window.addEventListener("resize", splitText);
    return () => window.removeEventListener("resize", splitText);
  }, [article]);

  const imageUrls = [
    article?.article_picture_1,
    article?.article_picture_2,
    article?.article_picture_3,
    article?.article_picture_4,
    article?.article_picture_5,
    article?.article_picture_6,
  ].filter((url): url is string => url !== null); // Ensuring only valid strings

  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const isLoggedInRedux: boolean = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );

  useEffect(() => {
    setIsLoggedIn(isLoggedInRedux);

    const checkLoginAndFetchArticles = async () => {
      if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
        const refreshTokens = isLoggedInRedux2.user.refreshToken;
        setRefreshtoken(refreshTokens);
        const refreshedAccessToken = await RefreshAccessToken(refreshTokens);
        setAccessToken(refreshedAccessToken);
        if (refreshedAccessToken) {
          await GetArticleData(refreshedAccessToken);
        }
      } else {
        console.log("User is not logged in or access token is missing.");
      }
    };
    checkLoginAndFetchArticles();
  }, [isLoggedInRedux, isLoggedInRedux2]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const GetArticleData = async (accessToken: string) => {
    try {
      const response = await axios.get(`${getArticleUrl}${id}/`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      const articleData = response.data.data;
      setArticle(articleData); // Store the article data in state
      setArticleTitle(articleData?.title);
      setArticleParagraph(articleData?.paragraph);
      setArticledateAndTime(articleData?.post_date_time);
      setEmbeddedLink(articleData?.embedded_link);
      if (articleData.article_picture) {
        setArticlePicture([
          {
            uid: "-1",
            name: "article_picture.png",
            status: "done",
            url: articleData.article_picture,
          },
        ]);
      }
      if (articleData.article_banner) {
        setBannerPicture([
          {
            uid: "-1",
            name: "article_banner.png",
            status: "done",
            url: articleData.article_banner,
          },
        ]);
      }
      setLoading(false); // Data has been loaded
    } catch (error) {
      console.log("Unexpected Error During Fetching Articles:", error);
      setLoading(false); // Data loading has failed
    }
  };

  return (
    <div>
      {loading ? (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <>
          {isMobile ? (
            <>
              {" "}
              <MobileDisplay
                article={article}
                bannerPicture={bannerPicture}
                imageUrls={imageUrls}
              />
            </>
          ) : (
            <>
              <DesktopDisplay
                article={article}
                bannerPicture={bannerPicture}
                imageUrls={imageUrls}
                visibleText={visibleText}
                remainingText={remainingText}
                textContainerRef={textContainerRef}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};
