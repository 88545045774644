import React from "react";
import { Typography, UploadFile, Flex, Carousel } from "antd";

type ArticleProps = {
  id: number;
  title: string;
  paragraph: string;
  author: {
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    id: number;
  };
  embedded_link: string | null;
  article_picture_1: string | null;
  article_picture_2: string | null;
  article_picture_3: string | null;
  article_picture_4: string | null;
  article_picture_5: string | null;
  article_picture_6: string | null;
  article_banner: string | null;
  post_date_time: string;
  status: string;
};
type MobileDisplayProps = {
  article: ArticleProps | null;
  bannerPicture: UploadFile[];
  imageUrls: string[];
};

const MobileDisplay: React.FC<MobileDisplayProps> = ({
  article,
  bannerPicture,
  imageUrls,
}) => {
  const { Title, Paragraph } = Typography;

  return (
    <div style={{ paddingBottom: "100px" }}>
      {" "}
      <div className="bannerContainer">
        {article?.article_banner && (
          <div
            className="imageContainer"
            style={{
              display: "flex",
              width: "100%",
              marginTop: "3%",
              justifyContent: "center",
            }}
          >
            <img
              src={bannerPicture[0]?.url}
              alt="Banner"
              style={{
                width: "100%",
                height: "auto",
                marginBottom: "4%",
                borderRadius: "2px",
              }}
            />
          </div>
        )}
      </div>
      <div className="titleContainer">
        <Title
          level={3}
          className="articleTitle"
          style={{
            textAlign: "center",
            fontFamily: "Inter, Arial, sans-serif", // Uses a modern sans-serif font
            marginTop: "0px",
            marginBottom: "0px",
          }}
        >
          {article?.title}
        </Title>
      </div>
      <div
        className="restTextContainer"
        style={{
          width: "100%",
          height: "auto",
          marginTop: "8px",
          textAlign: "justify",
          fontFamily: "Georgia, serif",
          fontSize: "18px",
          lineHeight: "1.6",
          color: "#333",
        }}
      >
        <Paragraph>{article?.paragraph}</Paragraph>
      </div>
      {article?.embedded_link && (
        <div style={{ width: "100%", marginTop: "20px" }}>
          <Flex justify="center">
            <iframe
              className="youtubeEmbeddedLink"
              width="330"
              height="200"
              src={article?.embedded_link}
              allowFullScreen
            ></iframe>
          </Flex>
        </div>
      )}
      {article && (
        <div
          className="imageContainer"
          style={{
            width: "100%",
            marginTop: "20px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Carousel arrows infinite={false}>
            {imageUrls.map((src, index) => (
              <div key={index}>
                <img
                  src={src}
                  alt={`Article Image ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default MobileDisplay;
