import React, { useEffect, useState } from "react";
import MainLayout from "../Layout/MainLayout";
import { Flex, Rate, Spin, Table, Tag } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import RefreshAccessToken from "../Authentication/RefreshToken";
import FetchRaceInformation from "../Mapper/raceInformationMapper";
import { useTranslation } from "react-i18next";
import fetchAllMetaDetailsOdds from "../CommonApiCalls/metaDataRaceInformation";
import LockedContent from "../LockedContent/LockedContent";
import TitleCardDesktop from "./Responsive Title/TitleCardDesktop";
import Segment from "./Responsive Title/Segment";
import ResultsNa8Pm from "./ResultsNa8Pm";
import fetchRaceStartTime from "../Mapper/raceSchedule";
import EightPMSchedule from "../OutputScheduler/EightPMSchedule";
import RaceInformationSaved from "../OutputScheduler/RaceInformationSaved";
import TitleCard from "./Responsive Title/TitleCard";

const StatisticsKing: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default StatisticsKing;

const PageDisplay: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const envUrl = process.env.REACT_APP_API_URL;
  const [selectedRace, setSelectedRace] = useState<any[]>([]);
  const [accessLevel, setAccessLevel] = useState<string | null>("");
  const statisticskingUrl = `${envUrl}statistics-king/`;
  const [isMobile, setIsMobile] = useState(false);
  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const { t } = useTranslation();
  const currentLanguage = useSelector((state: RootState) => state.language.lng);
  const [isSchedule, setIsScheduled] = useState<boolean>(false);
  const [restOfDetails, setRestOfDetails] = useState("");
  const [raceDayPlace, setRaceDayPlace] = useState("");

  const columns = [
    {
      title: t("race_number"),
      dataIndex: "Race-Number",
      key: "Race number",
      render: (text: string) => (
        <span style={{ fontSize: isMobile ? "0.7rem" : "inherit" }}>
          {text}
        </span>
      ),
    },
    {
      title: t("pre_match_prediction"),
      dataIndex: "Pre-match prediction",
      key: "Pre-match prediction",
      render: (text: number) => (
        <Rate
          style={{ fontSize: isMobile ? "0.7rem" : "inherit" }}
          disabled
          defaultValue={text * 10}
        />
      ),
    },
    {
      title: t("key_horses"),
      dataIndex: "Key-Horses",
      key: "Key-Horses",
      render: (horseString: string) => (
        <>
          {horseString
            .split("->")
            .slice(0, 4)
            .map((horse: string, index: number) => (
              <React.Fragment key={index}>
                <Tag
                  color="magenta"
                  style={{
                    fontSize: isMobile ? "0.5rem" : "inherit",
                    padding: isMobile ? "0px 5px" : "0px 6px",
                    marginRight: isMobile ? "2px" : "inherit",
                  }}
                >
                  {horse}
                </Tag>
                {index === 0 && (
                  <span
                    style={{
                      marginRight: "2px",
                      marginLeft: "1px",
                      fontSize: isMobile ? "0.6rem" : "inherit",
                    }}
                  >
                    {" "}
                    拖
                  </span>
                )}
                {isMobile && index < 3}{" "}
              </React.Fragment>
            ))}
        </>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
        const refreshToken = isLoggedInRedux2.user.refreshToken;

        const accessToken = await RefreshAccessToken(refreshToken);
        if (accessToken) {
          const [metaRaceData, metaRaceDataInfo] =
            await fetchAllMetaDetailsOdds(currentLanguage);
          const [
            savedMetaRaceData,
            savedMetaRaceInfoValue,
            responseValue,
            accessLevelValue,
          ] = await fetchStatisticsKingData(accessToken);

          console.log(savedMetaRaceData);
          const [raceStartDate, raceStartTime] = fetchRaceStartTime(
            1,
            metaRaceData
          );

          const [savedRaceStartDate, savedRaceStartTime] = fetchRaceStartTime(
            1,
            savedMetaRaceData
          );

          const isSchedule: boolean = await EightPMSchedule(
            raceStartTime,
            raceStartDate,
            savedRaceStartDate,
            savedRaceStartTime
          );
          if (isSchedule) {
            const raceDayPlaceVenue = Object.keys(savedMetaRaceInfoValue)
              .filter((key: any) => !isNaN(key))
              .sort((a: any, b: any) => a - b)
              .map((key: any) => savedMetaRaceInfoValue[key])
              .join("");

            const [raceTitle, raceDayPlace, restOfDetails] =
              FetchRaceInformation(
                1,
                raceDayPlaceVenue,
                savedMetaRaceData,
                currentLanguage
              );
            {
              setRaceDayPlace(raceDayPlace);
              setRestOfDetails(restOfDetails);
            }
            const responseData = responseValue
              .map((data: any) => ({
                "Pre-match prediction": data.Data["Pre-match prediction"],
                "Race-Number": raceInformation(
                  data["Race number"],
                  savedMetaRaceData,
                  savedMetaRaceInfoValue
                ),
                "Key-Horses": data.Data["Key horses"].join("->"),
              }))
              .filter(
                (item) => item["Race-Number"] !== "Race undefined - undefined"
              );

            setSelectedRace(responseData);
          } else {
            const raceDayPlaceVenue = Object.keys(metaRaceDataInfo)
              .filter((key: any) => !isNaN(key))
              .sort((a: any, b: any) => a - b)
              .map((key: any) => metaRaceDataInfo[key])
              .join("");

            const [raceTitle, raceDayPlace, restOfDetails] =
              FetchRaceInformation(
                1,
                raceDayPlaceVenue,
                metaRaceData,
                currentLanguage
              );
            {
              setRaceDayPlace(raceDayPlace);
              setRestOfDetails(restOfDetails);
            }
            const responseData = responseValue
              .map((data: any) => ({
                "Pre-match prediction": data.Data["Pre-match prediction"],
                "Race-Number": raceInformation(
                  data["Race number"],
                  metaRaceData,
                  metaRaceDataInfo
                ),
                "Key-Horses": data.Data["Key horses"].join("->"),
              }))
              .filter(
                (item) => item["Race-Number"] !== "Race undefined - undefined"
              );

            setSelectedRace(responseData);
          }

          setIsScheduled(isSchedule);
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [currentLanguage]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const raceInformation = (
    raceNumber: number,
    metaRaceData: any,
    metaRaceDataDetailsInfo: any
  ) => {
    const raceDayPlaceVenue = Object.keys(metaRaceDataDetailsInfo)
      .filter((key: any) => !isNaN(key)) // Exclude any non-numeric keys (e.g., "_persist")
      .sort((a, b) => a - b) // Sort the numeric keys in ascending order
      .map((key) => metaRaceDataDetailsInfo[key]) // Map the keys to their corresponding values
      .join(""); // Join them into a string
    const [raceTitle, raceDayPlace, restOfDetails] = FetchRaceInformation(
      raceNumber,
      raceDayPlaceVenue,
      metaRaceData,
      currentLanguage
    );
    console.log(raceTitle);

    return raceTitle;
  };

  const fetchStatisticsKingData = async (accessToken: string) => {
    let savedMetaRaceData: string = "";
    let responseValue: any = "";
    let accessLevelValue: string | null = "";
    let savedMetaRaceInfoValue: any = "";
    await axios
      .get(statisticskingUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const responseDataString = response.data.data.data;
        savedMetaRaceData = response?.data?.data?.meta_data?.data?.raceMeetings;

        // console.log(response.data.data.meta_data.data.raceMeetings[0].races);
        setAccessLevel(response.data.message);
        const [savedMetaData, savedMetaRaceInfo, savedVenueCode] =
          RaceInformationSaved(savedMetaRaceData, currentLanguage);
        savedMetaRaceData = savedMetaData;
        savedMetaRaceInfoValue = savedMetaRaceInfo;
        responseValue = responseDataString;
        accessLevelValue = accessLevel;
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
    return [
      savedMetaRaceData,
      savedMetaRaceInfoValue,
      responseValue,
      accessLevelValue,
    ];
  };

  const onChange = () => {};
  return (
    <div>
      {isMobile ? (
        <div>
          <Segment value="statistics_king" />
        </div>
      ) : (
        <></>
      )}

      {isMobile ? (
        <TitleCard
          title={t("statistics_king")}
          date={""}
          venue={""}
          day={""}
          maxRaceNumber={0}
          onChange={onChange}
        />
      ) : (
        <>
          <TitleCardDesktop
            title={t("statistics_king")}
            date={""}
            venue={""}
            day={""}
            maxRaceNumber={0}
            onChange={onChange}
          />
        </>
      )}
      <Flex
        justify="space-between"
        style={{
          marginBottom: "12px",
          paddingLeft: "4px",
          paddingRight: "4px",
        }}
      >
        <p
          style={{
            fontFamily: '"Montserrat", sans-serif',
            margin: "0px",
            fontSize: isMobile ? "0.6rem" : "0.8rem",
          }}
        >
          {" "}
          {raceDayPlace}
        </p>
        <p
          style={{
            fontFamily: '"Montserrat", sans-serif',
            margin: "0px",
            fontSize: isMobile ? "0.6rem" : "0.8rem",
          }}
        >
          {" "}
          {restOfDetails}
        </p>
      </Flex>
      {loading ? (
        <>
          <Spin tip="Loading" size="large">
            <Table
              dataSource={selectedRace} // Use selectedRace directly as an array
              columns={columns}
              size="small"
              pagination={false}
            />
          </Spin>
        </>
      ) : (
        <>
          {isSchedule ? (
            <>
              <Table
                dataSource={selectedRace} // Use selectedRace directly as an array
                columns={columns}
                size="small"
                pagination={false}
              />
              {accessLevel === "Premium content locked. Access limited." && (
                <LockedContent />
              )}
            </>
          ) : (
            <ResultsNa8Pm />
          )}
        </>
      )}
    </div>
  );
};
