import React, { useState, useEffect } from "react";
import {
  FacebookOutlined,
  TwitterOutlined,
  YoutubeOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Flex, Typography } from "antd";
import "../../styles/FooterLayout.css";
import { useTranslation } from "react-i18next";

const { Link: AntLink } = Typography;

const FooterLayout = () => {
  const { t } = useTranslation();
  const linkStyle: React.CSSProperties = {
    marginRight: "5px",
    marginLeft: "5px",
  };

  // State to store the current window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // State to store the responsive text
  const [responsiveText, setResponsiveText] = useState(
    t("responsive_text_one")
  );

  // Update the text based on screen size
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Set responsive text based on the window width
    if (windowWidth <= 768) {
      setResponsiveText(t("responsive_text_two"));
    } else {
      setResponsiveText(t("responsive_text_three"));
    }

    // Add event listener to update the width on resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);

  return (
    <div
      style={{
        position: "relative",
        textAlign: "center",
        color: "white",
        width: "100%",
        margin: 0,
      }}
    >
      <div
        className="bottomFooter"
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          width: "100%",
          padding: "10px 0",
          backgroundColor: "black",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Flex justify="center" style={{ width: "100%" }}>
          <p
            style={{ margin: 0, padding: 0, paddingLeft: 20 }}
            className="developedAt"
          >
            {t("footer_message")}
            <a
              href="https://www.youtube.com/@horseracing_upick"
              target="_blank"
              rel="noopener noreferrer"
              style={linkStyle}
            >
              <YoutubeOutlined />
            </a>
            <a
              href="https://www.facebook.com/upickhorse"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookOutlined />
            </a>
          </p>
          <p
            style={{ margin: 0, padding: 0, paddingRight: 20 }}
            className="additionalNavLinks"
          ></p>
        </Flex>
      </div>
    </div>
  );
};

export default FooterLayout;
