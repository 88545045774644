import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { FormProps, UploadFile } from "antd";
import {
  Button,
  Form,
  Input,
  Typography,
  Upload,
  Tooltip,
  Checkbox,
  message,
} from "antd";
import MainLayout from "../Layout/MainLayout";
import "../../styles/ArticleWrite.css";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { RcFile } from "antd/es/upload";
import RefreshAccessToken from "../Authentication/RefreshToken";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const { Title } = Typography;

type ArticleProps = {
  id: number;
  title: string;
  paragraph: string;
  author: {
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    id: number;
  };
  embedded_link: string | null;
  article_picture: string;
  article_banner: string;
  post_date_time: string;
  status: string;
};

const ArticleEdit: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default ArticleEdit;

const PageDisplay: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const envUrl = process.env.REACT_APP_API_URL;
  const refreshAccessUrl: string = envUrl + "token/refresh/";
  const editArticleUrl = envUrl + "articles/";
  const [loading, setLoading] = useState<boolean>(false);

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [embeddedLinkDisabled, setEmbeddedLinkDisabled] =
    useState<boolean>(true);
  const [bannerDisabled, setBannerDisabled] = useState<boolean>(true);
  const [pictureDisabled, setPictureDisabled] = useState<boolean>(true);
  const [article, setArticle] = useState<ArticleProps | null>(null);
  const [pictureFileList, setPictureFileList] = useState<UploadFile[]>([]);
  const [bannerFileList, setBannerFileList] = useState<UploadFile[]>([]);
  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const [refreshToken, setRefreshtoken] = useState<string | null>(null);
  const isLoggedInRedux: boolean = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    setIsLoggedIn(isLoggedInRedux);

    const checkLoginAndFetchArticles = async () => {
      if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
        const refreshTokens = isLoggedInRedux2.user.refreshToken;
        console.log(refreshToken);
        setRefreshtoken(refreshTokens);
        const accessToken = await RefreshAccessToken(refreshTokens);
        setAccessToken(accessToken);
        if (accessToken) {
          await GetArticleData(accessToken);
          console.log("User is logged in and articles fetched");
        }
      } else {
        console.log("User is not logged in or access token is missing.");
      }
    };
    checkLoginAndFetchArticles();
  }, [isLoggedInRedux, isLoggedInRedux2]);

  const GetArticleData = async (accessToken: string) => {
    console.log("Access token: " + accessToken);
    try {
      const response = await axios.get(`${editArticleUrl}${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const articleData = response.data.data;
      console.log(articleData);
      setArticle(articleData); // Store the article data in state

      // Set the form fields with the fetched article data
      form.setFieldsValue({
        title: articleData.title,
        paragraph: articleData.paragraph,
        embeddedLink: articleData.embedded_link,
      });

      console.log(articleData);
      const pictureFields = [
        articleData.article_picture_1,
        articleData.article_picture_2,
        articleData.article_picture_3,
        articleData.article_picture_4,
        articleData.article_picture_5,
        articleData.article_picture_6,
      ];

      // Filter out null values and create a file list for Upload
      const validImages: UploadFile[] = pictureFields
        .filter((image): image is string => image !== null)
        .map((image, index) => ({
          uid: `${index}`,
          name: `article_picture_${index + 1}.png`,
          status: "done",
          url: image,
        }));

      // Set the fileList only if there are valid images
      if (validImages.length > 0) {
        setPictureFileList(validImages);
      }

      if (articleData.article_banner) {
        setBannerFileList([
          {
            uid: "-1",
            name: "article_banner.png",
            status: "done",
            url: articleData.article_banner,
          },
        ]);
      }

      console.log(response.data);
    } catch (error) {
      console.log("Unexpected Error During Fetching Articles:", error);
    }
  };

  const WriteArticle = async (formData: FormData) => {
    console.log(formData.values());
    axios
      .patch(`${editArticleUrl}${id}/`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("Data posted successfully:", response.data);
        setLoading(false);
        message.success("Article Edited Successfully!");
        setTimeout(() => {
          navigate("/draft");
        }, 1000); // 1000 ms = 1 second
      })
      .catch((error) => {
        console.error("Error posting data:", error);
        setLoading(false);
        console.log(error.message);
        if (error.message == "Request failed with status code 413") {
          message.error(t("all_files_size_limit"));
        } else {
          message.error(t("something_went_wrong"));
        }
      })
      .finally(() => {
        setLoading(false);
        console.log("Post request completed.");
      });
  };

  const convertUrlToFile = async (url: string, index: number) => {
    try {
      const response = await fetch(url);
      console.log("response");
      console.log(url);
      const blob = await response.blob();
      const filename = `article_picture_${index + 1}.jpg`;
      console.log(filename);
      return new File([blob], filename, { type: blob.type });
    } catch (error) {
      console.error("Error converting URL to file:", error);
      return null;
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      const fieldsValue = form.getFieldsValue();
      console.log(fieldsValue);

      const formData = new FormData();

      formData.append("title", fieldsValue.title);
      formData.append("paragraph", fieldsValue.paragraph);
      formData.append("status", "DRAFT");

      if (embeddedLinkDisabled && fieldsValue.embeddedLink) {
        formData.append("embedded_link", fieldsValue.embeddedLink);
      }

      // Handle banner image
      if (bannerDisabled) {
        if (bannerFileList.length > 0) {
          const bannerFile = bannerFileList[0];

          if (bannerFile.originFileObj) {
            // Newly uploaded banner
            formData.append("article_banner", bannerFile.originFileObj);
          } else if (bannerFile.url) {
            // Existing banner from API (send URL)
            const file = await convertUrlToFile(bannerFile.url, 0);
            if (file) {
              formData.append("article_banner", file);
            }
          }
        } else {
          // Banner image was removed, append null or empty value
          formData.append("article_banner", ""); // Use "" or null based on your API
        }
      }

      // Handle pictures
      if (pictureDisabled) {
        const originalPictureCount = 6; // Assuming you have 6 picture fields

        // Iterate over the original picture count and append files or null
        for (let index = 0; index < originalPictureCount; index++) {
          const file = pictureFileList[index];

          if (file) {
            if (file.originFileObj) {
              // Newly uploaded file
              formData.append(
                `article_picture_${index + 1}`,
                file.originFileObj
              );
            } else if (file.url) {
              // File that needs to be downloaded from URL
              const downloadedFile = await convertUrlToFile(file.url, index);
              if (downloadedFile) {
                formData.append(`article_picture_${index + 1}`, downloadedFile);
              }
            }
          } else {
            // Picture was removed, append null or empty value
            formData.append(`article_picture_${index + 1}`, ""); // Use "" or null based on your API
          }
        }
      }

      const published_date = dayjs().format("YYYY-MM-DD");
      console.log(published_date);

      formData.append(`published_date`, published_date);

      const plainFormData = Object.fromEntries(formData.entries());
      console.log(plainFormData);
      await WriteArticle(formData);
    } catch (error) {
      console.error("Error preparing form data:", error);
    }
  };
  const onFinishFailed: FormProps["onFinishFailed"] = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handlePictureFileChange = (info: any) => {
    let fileList = [...info.fileList];
    setPictureFileList(fileList);
  };

  const handleBannerFileChange = (info: any) => {
    let fileList = [...info.fileList];
    setBannerFileList(fileList);
  };

  return (
    <div>
      <Title
        style={{
          margin: "0px",
          padding: "0px",
          display: "inline",
          fontFamily: "sans-serif",
        }}
        level={2}
      >
        {t("edit_article")}
      </Title>
      <p
        style={{
          display: "inline",
          marginLeft: "8px",
          fontSize: "16px",
          fontFamily: "sans-serif",
          color: "rgb(100, 100, 100)",
        }}
      >
        {t("saved_in_draft")}
      </p>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        style={{
          margin: "0px",
          padding: "0px",
          marginTop: "4%",
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label={t("article_title")}
          name="title"
          rules={[{ required: true, message: t("title_needed") }]}
        >
          <Input
            className="inputParametersArticleWrite"
            placeholder={t("insert_title")}
            value={article?.title}
            onChange={(e) => form.setFieldValue("title", e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label={t("paragraph")}
          name="paragraph"
          rules={[{ required: true, message: t("paragraph_needed") }]}
        >
          <TextArea
            rows={2}
            placeholder={t("insert_paragraph")}
            value={article?.paragraph}
            onChange={(e) => form.setFieldValue("paragraph", e.target.value)}
          />
        </Form.Item>
        <Checkbox
          checked={embeddedLinkDisabled}
          onChange={(e) => setEmbeddedLinkDisabled(e.target.checked)}
          style={{ marginBottom: "16px" }}
        >
          {t("include_youtube_link")}
        </Checkbox>
        {embeddedLinkDisabled && (
          <Form.Item
            label={
              <span>
                {t("youtube_embedded_link")}&nbsp;
                <Tooltip title={t("youtube_embedded_link_caution")}>
                  <ExclamationCircleOutlined style={{ color: "red" }} />
                </Tooltip>
              </span>
            }
            name="embeddedLink"
          >
            <Input
              className="inputParametersArticleWrite"
              placeholder={t("insert_embedded_link")}
              value={article?.embedded_link || ""}
              onChange={(e) =>
                form.setFieldValue("embeddedLink", e.target.value)
              }
            />
          </Form.Item>
        )}
        <Checkbox
          checked={bannerDisabled}
          onChange={(e) => setBannerDisabled(e.target.checked)}
          style={{ marginBottom: "16px" }}
        >
          {t("include_banner_picture")}
        </Checkbox>
        {bannerDisabled && (
          <Form.Item
            label={t("upload_banner_picture")}
            tooltip={{
              title: "Max size: 5MB",
              icon: <ExclamationCircleOutlined />,
            }}
          >
            <Upload
              listType="picture-card"
              maxCount={1}
              onChange={handleBannerFileChange}
              fileList={bannerFileList}
              beforeUpload={() => false} // Prevent automatic upload
            >
              {bannerFileList.length >= 1 ? null : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>{t("upload")}</div>
                </div>
              )}
            </Upload>
          </Form.Item>
        )}
        <Checkbox
          checked={pictureDisabled}
          onChange={(e) => setPictureDisabled(e.target.checked)}
          style={{ marginBottom: "16px" }}
        >
          {t("include_picture")}
        </Checkbox>

        {pictureDisabled && (
          <Form.Item label={t("upload_picture")} valuePropName="fileList">
            <Upload
              listType="picture-card"
              maxCount={6}
              showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
              fileList={pictureFileList}
              disabled={!pictureDisabled}
              onChange={handlePictureFileChange}
            >
              <button style={{ border: 0, background: "none" }} type="button">
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>{t("upload")}</div>
              </button>
            </Upload>
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t("saved_in_draft")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
