import { useMemo } from "react";
import {
  HomeOutlined,
  InfoCircleOutlined,
  FileTextOutlined,
  UserOutlined,
  BulbOutlined,
  ThunderboltOutlined,
  YoutubeOutlined,
  CreditCardOutlined,
  TrophyOutlined,
  FormOutlined,
  CrownOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import React from "react";
import { TFunction } from "i18next";

interface Author {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

type MenuItemProps = {
  isLoggedIn: boolean;
  isAdmin: boolean;
  isAuthor: boolean;
  authorsData: Author[];
  t: TFunction<"translation", undefined>;
};
const useMenuItems = ({
  isLoggedIn,
  isAdmin,
  isAuthor,
  authorsData,
  t,
}: MenuItemProps) => {
  const items = useMemo(
    () =>
      [
        isLoggedIn && {
          key: "profile",
          icon: <UserOutlined style={{ color: "black" }} />,
          label: <Link to="/profile">{t("profile")}</Link>,
        },
        isLoggedIn &&
          isAdmin && {
            key: "admin",
            icon: <CrownOutlined />,
            label: <Link to="/admin/home">{t("admin")}</Link>,
          },
        {
          label: t("race_information"),
          key: "SubMenu3",
          icon: <InfoCircleOutlined style={{ color: "black" }} />,
          children: [
            {
              type: "group",
              children: [
                {
                  key: "upcomingRace",
                  label: (
                    <Link to="/race_information/upcoming_race">
                      {t("upcoming_race")}
                    </Link>
                  ),
                },
                {
                  key: "WinPlace",
                  label: (
                    <Link to="/race_information/win_place">
                      {t("win_place")}
                    </Link>
                  ),
                },
                {
                  key: "QuinellaQuinella Place",
                  label: (
                    <Link to="/race_information/quinella">
                      {t("quinella/quinella_place")}
                    </Link>
                  ),
                },
                {
                  key: "Forecast",
                  label: (
                    <Link to="/race_information/forecast">{t("forecast")}</Link>
                  ),
                },
                {
                  key: "Trio",
                  label: <Link to="/race_information/trio">{t("trio")}</Link>,
                },
              ],
            },
          ],
        },
        isLoggedIn && {
          label: t("articles"),
          key: "articlesByAuthor",
          icon: <FileTextOutlined style={{ color: "black" }} />,
          children: [
            {
              type: "group",
              children: [
                {
                  key: "allArticles",
                  label: <Link to="/articles">{t("all_articles")}</Link>,
                },
                ...authorsData.map((author) => ({
                  key: `author-${author.id}`,
                  label: (
                    <Link to={`/articles/${author.id}`}>
                      {author.firstName} {author.lastName}
                    </Link>
                  ),
                })),
              ],
            },
          ],
        },
        (isAuthor || isAdmin) &&
          isLoggedIn && {
            label: t("author"),
            key: "author",
            icon: <FormOutlined style={{ color: "black" }} />,
            children: [
              {
                type: "group",
                children: [
                  {
                    key: "writeArticle",
                    label: <Link to="/write-article">{t("write")}</Link>,
                  },
                  {
                    key: "draftedArticles",
                    label: <Link to="/draft">{t("draft")}</Link>,
                  },
                  {
                    key: "publishedArticle",
                    label: <Link to="/published">{t("published")}</Link>,
                  },
                  {
                    key: "archievedArticles",
                    label: <Link to="/archived">{t("archieved")}</Link>,
                  },
                ],
              },
            ],
          },
        isLoggedIn && {
          label: t("tips"),
          key: "SubMenuTips",
          icon: <BulbOutlined style={{ color: "black" }} />,
          children: [
            {
              type: "group",
              children: [
                {
                  key: "statisticKing",
                  label: (
                    <Link to="/tips/statistics_king">
                      {t("statistic_king")}
                    </Link>
                  ),
                },
                {
                  key: "horseStrengthIndex",
                  label: (
                    <Link to="/tips/horse_strength_index">
                      {t("horse_strength_index")}
                    </Link>
                  ),
                },
                {
                  key: "FinalTips",
                  label: <Link to="/tips/Final_Tip">{t("final_tips")}</Link>,
                },
              ],
            },
          ],
        },
        isLoggedIn && {
          label: t("instant_betting_suggestion"),
          key: "instantBettingSuggestion",
          icon: <TrophyOutlined style={{ color: "black" }} />,
          children: [
            {
              type: "group",
              children: [
                {
                  key: "instantkeyHorse",
                  label: (
                    <Link to="/instantBettingSuggestion/instant_key_horse">
                      {t("instant_key_horse")}
                    </Link>
                  ),
                },
                {
                  key: "InstantAnalysis",
                  label: (
                    <Link to="/instantBettingSuggestion/InstantComprehensiveDataAnalysis">
                      {t("instant_analysis")}
                    </Link>
                  ),
                },
                {
                  key: "InstantHorseTrend",

                  label: (
                    <Link to="/instantBettingSuggestion/InstantHorseTrendRecommendation">
                      {t("instant_horse_trend")}
                    </Link>
                  ),
                },
              ],
            },
          ],
        },
        {
          key: "youtube",
          icon: <YoutubeOutlined style={{ color: "black" }} />,
          label: <Link to="/youtubeVideoLinks">{t("youTube")}</Link>,
        },
        isLoggedIn && {
          key: "paymentAndPricing",
          icon: <CreditCardOutlined style={{ color: "black" }} />,
          label: <Link to="/plans/pricing-and-plans">{t("payment")}</Link>,
        },
      ].filter(Boolean),
    [isLoggedIn, isAdmin, isAuthor, authorsData, t]
  );

  return items;
};

export default useMenuItems;
